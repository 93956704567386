import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './JoinVodaPayClub.css';
import TagIcon from '../../assets/TagIcon.jsx';
import PercentIcon from '../../assets/PercentIcon.jsx';
import MerchantIcon from '../../assets/MerchantIcon.jsx';
import ROUTES from '../../services/Constants/GlobalRoutes.jsx';
import TopBack from '../../components/TopBack/TopBack.jsx';
import VodaPayClubTicket from '../../assets/VodaPayClubTicket.jsx';
import { SubscriptionDetailsContext } from '../../services/context/SubscriptionDetailsContext.js';
import { UpdateSnackBarContext } from '../../services/context/SnackBarContext.js';
import FailIcon from '../../assets/FailIcon.jsx';
import { defaultAnalyticsVariables, events, pagePrefix } from '../../services/Constants/Analytics.js';

/**
 * @returns {JSX.Element} JoinVodaPayClub Component
 */
export default function JoinVodaPayClub() {
  const navigateTo = useNavigate();
  const analyticsName = `${pagePrefix}: join vodapay club`;
  const subscriptionDetails = useContext(SubscriptionDetailsContext);
  const setSnackBar = useContext(UpdateSnackBarContext);

  const isSubscribed = subscriptionDetails?.isSubscribed;
  const isTrialAvailable = subscriptionDetails?.isTrialAvailable;
  const subscriptionStatus = subscriptionDetails?.subscriptionStatus;
  const subscriptionStatusAnalytics = subscriptionDetails?.subscriptionStatus === 'unsubscribed' ? 'not active' : subscriptionDetails?.subscriptionStatus;

  const buttonText = (!isSubscribed && isTrialAvailable) ? 'Try one week for R0.00' : 'subscribe';

  useEffect(() => {
    window.utag?.view({
      ...defaultAnalyticsVariables,
      event_name: [events.pageView],
      page_name: analyticsName,
      visitor_addon_type_active: `${defaultAnalyticsVariables.app_name}: subscription: ${subscriptionStatusAnalytics}`,
    });
  });

  const onClickAnalytics = () => {
    window.utag?.link({
      ...defaultAnalyticsVariables,
      event_name: [events.interaction],
      page_name: analyticsName,
      link_id: `${pagePrefix}: premium vouchers: try now`,
      visitor_addon_type_active: `${defaultAnalyticsVariables.app_name}: subscription: ${subscriptionStatusAnalytics}`,
    });
  };

  const handleContinue = status => {
    if (status === 'unsubscribed') {
      navigateTo(ROUTES.SUB_SUMMARY);
    } else {
      setSnackBar({
        icon: <FailIcon />,
        body: 'Whoops, Something went wrong',
      });
    }
  };

  return (
    <div className='top-container'>
      <TopBack />
      <div className='image-container'>
        <VodaPayClubTicket />
      </div>
      <h2 className='title'>Join the VodaPay Club</h2>
      <div className='join-club-container'>
        <div className='join-club-child-container'>
          <div className='join-club-sub-child-container'>
            <div className='icon'>
              <TagIcon />
              <div className='text'>
                <span className='subtext-header'>Exclusive details selected for you</span>
                <span className='subtext'>Get Premium Vouchers & Premium Deals</span>
              </div>
            </div>
            <div className='percent-icon'>
              <PercentIcon />
              <div className='text'>
                <span className='subtext-header'>Up to 50% in value &amp; savings</span>
                <span className='subtext'>Enjoy up to 50% more with Premium</span>
              </div>
            </div>
            <div className='icon'>
              <MerchantIcon />
              <div className='text'>
                <span className='subtext-header'>Premium Voucher Stores</span>
                <span className='subtext'>Shop Premium brands at the best prices</span>
              </div>
            </div>
          </div>
        </div>
        <span className='autoRenewText'>Auto renews <strong>R{Number(subscriptionDetails?.subscriptionFee).toFixed(2)}/Month</strong>. You can cancel at any time.</span>
        <button
          className='joinClub-button'
          onClick={
            () => {
              handleContinue(subscriptionStatus);
              onClickAnalytics();
            }
          }
        >
          {buttonText}
        </button>
      </div>
    </div>
  );
}
