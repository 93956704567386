import { useContext } from 'react';
import { ConfigContext } from '../context/ConfigContext.js';
import { UserDetailsContext } from '../context/UserDetailsContext.js';
import { replaceURLPathParams } from '../StringProcessor.js';
import { CurrentEnvironment, Environments } from '../../Env.js';
import { MoneyFormatterContext } from '../context/MoneyFormatterContext.js';
import { Voucher, Eligibility, UserDetails } from '../Constants/ObjectDefinitions.js';
import { BearerData, CatalogueData, CreateAdvanceResponse, CreatePaymentResponse, HistoryData } from '../Constants/ApiResponses.js';
import { NetworkError } from '../Constants/Errors.js';

const fetchAsObject = (url, options, from = false) => fetch(url, options)
  .then(response => {
    if ((response.ok && !from) || from) {
      return response.json();
    }

    throw new NetworkError(response);
  });

const json = 'application/json';
const jsonContent = { 'Content-Type': json };
const vodapayGatewayHeaders = {
  'request-time': '0001-01-01T00:00:00.000+00:00', // This field is required but as far as I can see the value doesn't matter as long as it is a valid DateTime
  signature: 'algorithm=RSA256, keyVersion=1, signature=testing_signatur',
};

/**
 * @returns {Promise<*>} - response data
 */
export const getConfig = async () => fetchAsObject(Environments[CurrentEnvironment].configUrl, {
  method: 'GET',
  headers: { ...jsonContent },
})
  .then(response => response.data);

export default () => {
  const config = useContext(ConfigContext);
  const { apiChannel } = config.external.dxl;
  const { store } = config.external.magento.channels.vpay;
  const { createPaymentUrl } = config.external.vodapayGateway.dxl;
  const { userId, msisdn } = useContext(UserDetailsContext);
  const MoneyFormatter = useContext(MoneyFormatterContext);

  /**
   * @returns {Promise<{authCode: string}>} - response data
   */
  const getAuth = async () => {
    const { applyAuthCodeUrl } = config.external.vodapayGateway.v2;
    const { clientId } = config.external.vodapayGateway;
    const options = {
      method: 'POST',
      headers: {
        ...jsonContent,
        'SOFA-RpcId': '0',
        'SOFA-TraceId': '20210224000010086009',
        'client-id': clientId,
        ...vodapayGatewayHeaders,
      },
      body: JSON.stringify({
        clientId,
        userId,
        scopes: 'auth_user',
      }),
    };

    return fetchAsObject(applyAuthCodeUrl, options);
  };

  /**
   * @param {string} authCode - mini app auth code
   * @returns {Promise<{data: BearerData}>} - response data
   */
  const getBearerToken = async authCode => {
    if (!authCode) {
      throw new Error('Auth Error: Unable to get authCode');
    }

    const { fsAuthUrl } = config.backend.dxl.v1;
    const options = {
      method: 'POST',
      headers: jsonContent,
      body: JSON.stringify({ authCode }),
    };

    return fetchAsObject(fsAuthUrl, options);
  };

  /**
   * @param {string} bearerToken - Authorization Token
   * @returns {Promise<{data: CatalogueData}>} - response data
   */
  const getCategories = async bearerToken => {
    const { catalogueUrl } = config.backend.magento.v1;
    const options = {
      method: 'GET',
      headers: { Authorization: `Bearer ${bearerToken}` },
    };
    const queryParams = new URLSearchParams({ store });

    return fetchAsObject(`${catalogueUrl}?${queryParams.toString()}`, options);
  };

  /**
   * @param {string} bearerToken - Authorization Token
   * @returns {Promise<{cartId: string}>} - response data
   */
  const getCart = async bearerToken => {
    const { cartCreateUrl } = config.backend.magento.v1;
    const options = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        ...jsonContent,
      },
      body: JSON.stringify({ store }),
    };
    return fetchAsObject(cartCreateUrl, options)
      .then(response => response.data);
  };

  /**
   * @param {string} bearerToken - Authorization Token
   * @param {string} cartId - Magento cart ID
   * @param {Array} cartItems - formatted array of items in cart
   * @returns {Promise<{cartId: string}>} - response data
   */
  const addToCart = async (bearerToken, cartId, cartItems) => {
    const { cartAddUrl } = config.backend.magento.v1;
    const options = {
      method: 'POST',
      headers: {
        'x-api-channel': apiChannel,
        ...jsonContent,
        Authorization: `Bearer ${bearerToken}`,
      },
      body: JSON.stringify({
        cartItems,
        cartId,
        store,
      }),
    };

    return fetchAsObject(cartAddUrl, options)
      .then(response => response.data);
  };

  /**
   * @param {string} bearerToken - Authorization Token
   * @param {string} cartId - Magento cart ID
   * @param {UserDetails} personalDetails - user's personal details
   * @returns {Promise<{cartId: string, orderId: number}>} - response data
   */
  const checkoutCart = async (bearerToken, cartId, { email, firstName, lastName, fullName }) => {
    const { cartCheckoutUrl } = config.backend.magento.v1;
    const options = {
      method: 'POST',
      headers: {
        'x-api-channel': apiChannel,
        ...jsonContent,
        Authorization: `Bearer ${bearerToken}`,
      },
      body: JSON.stringify({
        customer: {
          email,
          firstName,
          lastName,
          fullName,
        },
        store,
        cartId,
      }),
    };
    return fetchAsObject(cartCheckoutUrl, options)
      .then(response => response.data);
  };

  /**
   * @param {string} bearerToken - Authorization Token
   * @param {number} pageSize - number of promos per page
   * @param {number} currentPage - current page requested
   * @returns {Promise<{items: Array, totalCount: number}>} - response data
   */
  const getPromos = async (bearerToken, pageSize, currentPage) => {
    const { promoGetUrl } = config.backend.magento.v1;
    const options = {
      method: 'GET',
      headers: {
        ...jsonContent,
        Authorization: `Bearer ${bearerToken}`,
      },
    };
    const queryParams = new URLSearchParams({
      store,
      pageSize,
      currentPage,
    });

    return fetchAsObject(`${promoGetUrl}?${queryParams.toString()}`, options)
      .then(response => response.data);
  };

  /**
   * @param {string} bearerToken - Authorization Token
   * @param {{voucher, sequenceNo, orderId}} config - config for reservation
   * @param {Voucher} config.voucher - voucher to reserve
   * @param {number} config.orderId - Magento order ID
   * @param {number} config.sequenceNo - sequence in cart
   * @param {boolean} [isAdvance] - true if advance
   * @returns {Promise<{orderId: number, reservationId: string, sequenceNo: number, sourceId: string, voucherId: string}>} - response data
   */
  const reserveVoucher = async (bearerToken, { voucher, sequenceNo, orderId }, isAdvance = false) => {
    const { voucherReserveUrl } = config.backend.cvm.v1;
    const cost = MoneyFormatter.getValue(voucher.cost).amount;
    const value = MoneyFormatter.getValue(voucher.options[isAdvance ? 'advance' : 'buyNow'].value).amount;
    const options = {
      method: 'POST',
      headers: {
        'x-api-channel': apiChannel,
        ...jsonContent,
        Authorization: `Bearer ${bearerToken}`,
      },
      body: JSON.stringify({
        voucherId: voucher.id,
        isAdvance,
        cost,
        value,
        name: voucher.name,
        sequenceNo,
        orderId,
        channel: apiChannel,
        productType: voucher.stock.type,
      }),
    };

    return fetchAsObject(voucherReserveUrl, options)
      .then(response => response.data);
  };

  /**
   * @param {string} bearerToken - Authorization Token
   * @param {number} orderId - Magento order ID
   * @param {Array} reservations - Array of reserved vouchers
   * @returns {Promise<{orderId: number, order: object}>} - response data
   */
  const reserveOrder = async (bearerToken, orderId, reservations) => {
    const { cartReserveUrl } = config.backend.magento.v1;
    const options = {
      method: 'POST',
      headers: {
        'x-api-channel': apiChannel,
        ...jsonContent,
        Authorization: `Bearer ${bearerToken}`,
      },
      body: JSON.stringify({
        orderId,
        reservations,
        store,
      }),
    };

    return fetchAsObject(cartReserveUrl, options)
      .then(response => response.data);
  };

  /**
   * @param {string} bearerToken - Authorization Token
   * @param {string} authCode - mini app Authorization Code for payment
   * @param {{orderId, paymentAmount, reservationId}} config - config for early repayment
   * @param {number} config.orderId - Magento order ID
   * @param {number} config.paymentAmount - numeric amount to be repaid (in ZAR)
   * @param {number} config.reservationId - Associated reservation ID on cvm
   * @returns {Promise<*>} - response
   */
  const createEarlyRepayment = async (bearerToken, authCode, { orderId, paymentAmount, reservationId }) => {
    const options = {
      method: 'POST',
      headers: {
        'x-api-channel': apiChannel,
        ...jsonContent,
        Authorization: `Bearer ${bearerToken}`,
      },
      body: JSON.stringify({
        paymentMethod: 'vodapaycashierpayment',
        type: 'advancemesettlenowpayment',
        vodaPayAuthCode: authCode,
        additionalInfo: {
          externalLoanId: reservationId,
          paymentAmount,
        },
      }),
    };
    const url = replaceURLPathParams(createPaymentUrl, {
      msisdn,
      orderId,
    });

    return fetchAsObject(url, options);
  };

  /**
   * @param {string} bearerToken - Authorization Token
   * @param {string} authCode - mini app auth code
   * @param {object} config - config for advance payment
   * @param {string} config.eligibilityRequestId - user's eligibility ID
   * @param {number} config.orderId - Magento order ID
   * @param {Date} config.paymentDate - date on which account will be debited
   * @param {string} config.offerId - cvas offer ID
   * @returns {Promise<CreateAdvanceResponse>} - response data
   */
  const createAdvancePayment = async (bearerToken, authCode, { eligibilityRequestId, orderId, paymentDate, offerId }) => {
    const [firstRepaymentDate] = paymentDate.toISOString().split('T');
    const options = {
      method: 'GET',
      headers: {
        'x-api-channel': apiChannel,
        'Content-Type': 'application/x-www-form-urlencoded',
        accept: json,
        Authorization: `Bearer ${bearerToken}`,
      },
    };
    const queryParams = new URLSearchParams({
      paymentMethod: 'vodacomtraderoot',
      type: 'advancemetokenpayment',
      vodaPayAuthCode: authCode,
      offerId,
      eligibilityRequestId,
      firstRepaymentDate,
    });
    const url = `${replaceURLPathParams(createPaymentUrl, {
      msisdn,
      orderId,
    })}?${queryParams.toString()}`;

    return fetchAsObject(url, options);
  };

  /**
   * @param {string} bearerToken - Authorization Token
   * @param {string} authCode - mini app auth code
   * @param {object} config - config for change card
   * @param {number} config.orderId - Magento order ID
   * @param {Date} config.firstRepaymentDate - date of loan maturity
   * @returns {Promise<*>} - response data
   */
  const changeCardCall = async (bearerToken, authCode, { orderId, firstRepaymentDate }) => {
    const options = {
      method: 'GET',
      headers: {
        'x-api-channel': apiChannel,
        accept: json,
        Authorization: `Bearer ${bearerToken}`,
      },
    };
    const queryParams = new URLSearchParams({
      type: 'advancemechangecard',
      paymentMethod: 'vodacomtraderoot',
      vodaPayAuthCode: authCode,
      payer: msisdn,
      transactionId: orderId,
      firstRepaymentDate: (firstRepaymentDate < new Date() ? new Date() : firstRepaymentDate).toISOString().split('T')[0],
    });
    const url = `${replaceURLPathParams(createPaymentUrl, {
      msisdn,
      orderId,
    })}?${queryParams.toString()}`;

    return fetchAsObject(url, options);
  };

  /**
   * @param {string} bearerToken - Authorization Token
   * @param {string} authCode - mini app auth code
   * @param {number} orderId - Magento order ID
   * @returns {Promise<CreatePaymentResponse>} - response data
   */
  const createPayment = async (bearerToken, authCode, orderId) => {
    const options = {
      method: 'GET',
      headers: {
        'x-api-channel': apiChannel,
        accept: json,
        Authorization: `Bearer ${bearerToken}`,
      },
    };
    const queryParams = new URLSearchParams({
      type: 'advancemeonceoffpayment',
      paymentMethod: 'vodapaycashierpayment',
      vodaPayAuthCode: authCode,
    });
    const url = `${replaceURLPathParams(createPaymentUrl, {
      msisdn,
      orderId,
    })}?${queryParams.toString()}`;

    return fetchAsObject(url, options);
  };

  /**
   * @param {string} bearerToken - Authorization Token
   * @returns {Promise<Eligibility>} - response data
   */
  const getAdvanceEligibility = async bearerToken => {
    const { eligibilityTclUrl } = config.backend.cvas.v1;
    const options = {
      method: 'GET',
      headers: {
        'x-api-channel': apiChannel,
        ...jsonContent,
        Authorization: `Bearer ${bearerToken}`,
      },
    };

    const queryParams = new URLSearchParams({ channel: apiChannel });
    return fetchAsObject(`${eligibilityTclUrl}?${queryParams.toString()}`, options)
      .then(response => response.data);
  };

  const getLoanDetails = async (bearerToken, reservationId) => {
    const { loanBalanceUrl } = config.backend.cvas.v1;
    const options = {
      method: 'GET',
      headers: {
        'x-api-channel': apiChannel,
        ...jsonContent,
        Authorization: `Bearer ${bearerToken}`,
      },
    };
    const queryParams = new URLSearchParams({ reservationId });
    const url = `${loanBalanceUrl}?${queryParams.toString()}`;
    return fetchAsObject(url, options)
      .then(response => response.data);
  };

  /**
   * @param {string} bearerToken - Authorization Token
   * @returns {Promise<{ vouchers: Array<HistoryData> }>} - response data
   */
  const getHistory = async bearerToken => {
    const { historyVouchersUrl } = config.backend.cvm.v1;
    const options = {
      method: 'GET',
      headers: {
        'x-api-channel': apiChannel,
        ...jsonContent,
        Authorization: `Bearer ${bearerToken}`,
      },
    };
    const queryParams = new URLSearchParams({
      withinNumDays: String(365 * 5),
      sortDate: 'desc',
    });
    const url = `${historyVouchersUrl}?${queryParams.toString()}`;

    return fetchAsObject(url, options)
      .then(response => response.data);
  };

  /**
   * @param {string} bearerToken - Authorization Token
   * @param {UserDetails} userDetails - User Details
   * @param {string} orderId - Invoice order id
   * @returns {Promise<{orderId, customer}>} Acknowledge
   */
  const resendInvoice = async (bearerToken, { email, firstName, lastName, fullName }, orderId) => {
    const { invoiceSendUrl } = config.backend.magento.v1;
    const options = {
      method: 'POST',
      headers: {
        'x-api-channel': apiChannel,
        ...jsonContent,
        Authorization: `Bearer ${bearerToken}`,
      },
      body: JSON.stringify({
        customer: {
          email,
          firstName,
          lastName,
          fullName,
        },
        orderId,
        store,
      }),
    };
    return fetchAsObject(invoiceSendUrl, options);
  };

  /**
   * @param {string} bearerToken - Authorization Token
   * @returns {Promise<{ details: Array<HistoryData> }>} - response data
   */
  const getSubscriptionDetails = async bearerToken => {
    const { subscriptionDetailsUrl } = config.backend.dls.v1;
    const options = {
      method: 'GET',
      headers: {
        'x-api-channel': apiChannel,
        ...jsonContent,
        Authorization: `Bearer ${bearerToken}`,
      },
    };
    const queryParams = new URLSearchParams({ channel: 'V-PAY' });
    return fetchAsObject(`${subscriptionDetailsUrl}?${queryParams.toString()}`, options, true)
      .then(response => response);
  };

  /**
   * @param {string} bearerToken - Authorization Token
   * @returns {Promise[response]} - response data
   */
  const getBalances = async bearerToken => {
    const { getBalanceUrl } = config.backend.dxl.v1.getBalanceUrl;
    const options = {
      method: 'GET',
      headers: {
        'x-api-channel': apiChannel,
        ...jsonContent,
        Authorization: `Bearer ${bearerToken}`,
      },
    };
    const url = `${replaceURLPathParams(getBalanceUrl, { msisdn })}`;

    return fetchAsObject(url, options)
      .then(response => response);
  };

  /**
   * Activate Subscription POST API
   *
   * @param {string} bearerToken - Authorization Token
   * @param {string} packageId - subscription package Id
   * @returns {Promise<Response>} - response data
   */
  const activateSubscription = async (bearerToken, packageId) => {
    const { activateSubscriptionUrl } = config.backend.dls.v1;

    const options = {
      method: 'POST',
      headers: {
        'x-api-channel': apiChannel,
        ...jsonContent,
        Authorization: `Bearer ${bearerToken}`,
      },
      body: JSON.stringify({ packageId }),
    };
    const queryParams = new URLSearchParams({ channel: 'V-PAY' });
    return fetchAsObject(`${activateSubscriptionUrl}?${queryParams.toString()}`, options)
      .then(response => response);
  };

  /**
   * Deactivate Subscription POST API
   *
   * @param {string} bearerToken - Authorization Token
   * @param {string} subscriptionIdFromApi - Subscription ID from the API
   * @returns {Promise<Eligibility>} - response data
   */
  const inactivateSubscription = async (bearerToken, subscriptionIdFromApi) => {
    const { inactivateSubscriptionUrl } = config.backend.dls.v1;

    const options = {
      method: 'POST',
      headers: {
        'x-api-channel': apiChannel,
        ...jsonContent,
        Authorization: `Bearer ${bearerToken}`,
      },
    };

    const queryParams = new URLSearchParams({
      channel: 'V-PAY',
      subscriptionId: subscriptionIdFromApi,
    });
    return fetchAsObject(`${inactivateSubscriptionUrl}?${queryParams.toString()}`, options)
      .then(response => response);
  };

  return {
    addToCart,
    changeCardCall,
    checkoutCart,
    createAdvancePayment,
    createEarlyRepayment,
    createPayment,
    getAdvanceEligibility,
    getAuth,
    getBearerToken,
    getCart,
    getCategories,
    getLoanDetails,
    getHistory,
    getPromos,
    resendInvoice,
    reserveOrder,
    reserveVoucher,
    getBalances,
    getSubscriptionDetails,
    activateSubscription,
    inactivateSubscription,
  };
};
