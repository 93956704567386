import React, { useEffect } from 'react';
import './App.css';
import MainContent from './components/MainContent/MainContent.jsx';
import { BrowserRouter, Route, Routes, createRoutesFromChildren, useLocation, useNavigate, matchRoutes } from 'react-router-dom';
import PropTypes from 'prop-types';
import ContextController from './controllers/ContextController.jsx';
import RocketPage from './pages/Rocket/RocketPage.jsx';
import ManagePaymentPage from './pages/ManagePayment/ManagePaymentPage.jsx';
import PayAdvancePage from './pages/PayAdvance/PayAdvancePage.jsx';
import PaymentSummary from './pages/PaymentSummary/PaymentSummary.jsx';
import ROUTES from './services/Constants/GlobalRoutes.jsx';
import SnackBar from './components/SnackBar/SnackBar.jsx';
import NotFound from './pages/NotFound/NotFound.jsx';
import MaintenancePage from './pages/Maintenance/MaintenancePage.jsx';
import CrossSell from './pages/CrossSell/CrossSell.jsx';
import ShareCoupon from './pages/ShareCoupon/ShareCoupon.jsx';
import FallenRocketErrorPage from './pages/FallenRocketErrorPage/FallenRocketErrorPage.jsx';
import PurchaseSuccessful from './pages/PurchaseSuccessful/PurchaseSuccessful.jsx';
import SubscriptionSummary from './pages/SubscriptionSummary/SubscriptionSummary.jsx';
// eslint-disable-next-line no-unused-vars
import { GroceriesImage, VodapayLogoRed, VodapayLogoWhite } from './services/Constants/assetsImports.js';
import {
  init,
  BrowserTracing,
  reactRouterV6Instrumentation,
  withSentryReactRouterV6Routing,
} from '@sentry/react';
import { Environments, CurrentEnvironment } from './Env.js';
import { msisdnMasker } from './services/StringProcessor.js';
import AdvanceSummary from './pages/AdvanceSummary/AdvanceSummary.jsx';
import VoucherInfo from './pages/VoucherInfo/VoucherInfo.jsx';
import Popup from './components/Popup/Popup.jsx';
import ExtraValueTryNowBox from './pages/ExtraValueTryNowBox/ExtraValueTryNowBox.jsx';
import AreYouSurePage from './pages/AreYouSurePage/AreYouSurePage.jsx';
import CancellationPage from './pages/CancellationPage/CancellationPage.jsx';

const beforeSendSentry = event => {
  if (CurrentEnvironment === 'dev') return null;
  return event;
};

const beforeSendTransactionSentry = event => {
  if (CurrentEnvironment === 'dev') return null;
  return event;
};

const beforeBreadcrumbSentry = crumb => {
  if (crumb.category === 'console'
    && (crumb?.message?.includes('vite')
    || crumb?.message?.includes('Kampyle'))) return null;

  if (crumb.category === 'fetch' || crumb.category === 'xhr') crumb.data.url = msisdnMasker(crumb.data.url);

  return crumb;
};

init({
  dsn: Environments[CurrentEnvironment].sentryConfig.dsn,
  environment: CurrentEnvironment,
  tracesSampleRate: Environments[CurrentEnvironment].sentryConfig.tracesSampleRate,
  profilesSampleRate: Environments[CurrentEnvironment].sentryConfig.profilesSampleRate,
  tracePropagationTargets: [ // Send sentry info to these domains only
    'localhost',
    /^.*\.sentry.io\.*/,
    /^\/.*/, // I.e., '/api/endpoint'
  ],
  integrations: [
    new BrowserTracing({
      routingInstrumentation: reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigate,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
  ],
  beforeSend: beforeSendSentry,
  beforeSendTransaction: beforeSendTransactionSentry,
  beforeBreadcrumb: beforeBreadcrumbSentry,
});

const SentryRoutes = withSentryReactRouterV6Routing(Routes);

/**
 * @returns {JSX.Element} App Component
 */
function App() {
  return (
    <BrowserRouter>
      <ContextController
        slot={
          <div className='App v-container'>
            <SentryRoutes>
              <Route
                path={ ROUTES.VOUCHER_INFO }
                element={ <VoucherInfo /> }
              />
              <Route
                path={ ROUTES.ADVANCE_SUMMARY }
                element={ <AdvanceSummary /> }
              />
              <Route
                path={ ROUTES.SUCCESS }
                element={ <RocketPage /> }
              />
              <Route
                path={ ROUTES.MANAGE_ADVANCE }
                element={ <ManagePaymentPage /> }
              />
              <Route
                path={ ROUTES.PAYMENT_SELECT }
                element={ <PayAdvancePage /> }
              />
              <Route
                path={ ROUTES.SUMMARY }
                element={ <PaymentSummary /> }
              />
              <Route
                path={ ROUTES.NOT_FOUND }
                element={ <NotFound /> }
              />
              <Route
                path={ ROUTES.MAINTENANCE }
                element={ <MaintenancePage /> }
              />
              <Route
                path={ ROUTES.ERROR }
                element={ <MaintenancePage /> }
              />
              <Route
                path={ ROUTES.SHARE }
                element={ <ShareCoupon /> }
              />
              <Route
                path={ ROUTES.FAILURE }
                element={ <RocketPage isFailure /> }
              />
              <Route
                path={ ROUTES.SUB_SUMMARY }
                element={ <SubscriptionSummary /> }
              />
              <Route
                path={ ROUTES.CROSS_SELL }
                element={ <CrossSell /> }
              />
              <Route
                path={ ROUTES.VODAPAY_CLUB_STORE }
                element={ <ExtraValueTryNowBox /> }
              />
              <Route
                path={ ROUTES.ARE_YOU_SURE }
                element={ <AreYouSurePage /> }
              />
              <Route
                path={ ROUTES.CANCELLATION }
                element={ <CancellationPage /> }
              />
              <Route
                path={ ROUTES.PURCHASE_SUCCESSFUL }
                element={ <PurchaseSuccessful /> }
              />
              <Route
                path={ ROUTES.ROCKET_ERROR }
                element={ <FallenRocketErrorPage /> }
              />
              <Route
                path='*'
                element={ <MainContent /> }
              />
            </SentryRoutes>
            <Popup />
            <SnackBar />
          </div>
        }
      />
    </BrowserRouter>
  );
}

App.propTypes = { bearerToken: PropTypes.string };

export default App;
