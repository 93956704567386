import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './AreYouSurePage.css';
import { withProfiler, captureException } from '@sentry/react';
import ROUTES from '../../services/Constants/GlobalRoutes.jsx';
import BagIcon from '../../assets/BagIcon.jsx';
import DualButtons from '../../components/DualButtons/DualButtons.jsx';
import TopBack from '../../components/TopBack/TopBack.jsx';
import useApis from '../../services/hooks/useApis.js';
import { BearerTokenContext } from '../../services/context/BearerTokenContext.js';
import Loading from '../../components/Loading/Loading.jsx';
import { NetworkError } from '../../services/Constants/Errors.js';
import { UpdateSnackBarContext } from '../../services/context/SnackBarContext.js';
import FailIcon from '../../assets/FailIcon.jsx';
import { SubscriptionDetailsContext } from '../../services/context/SubscriptionDetailsContext.js';
import { defaultAnalyticsVariables, events, pagePrefix } from '../../services/Constants/Analytics.js';

/**
 * @returns {JSX.Element} AreYouSurePage component
 */
function AreYouSurePage() {
  const analyticsName = `${pagePrefix}: manage subscription: cancel confirmation`;
  const navigateTo = useNavigate();
  const bearerToken = useContext(BearerTokenContext);
  const { inactivateSubscription } = useApis();
  const setSnackBar = useContext(UpdateSnackBarContext);
  const [loading, setLoading] = useState(/** @type {string} */null);
  const subscriptionDetails = useContext(SubscriptionDetailsContext);
  let subscriptionId = null;
  const subscriptionStatus = subscriptionDetails?.subscriptionStatus === 'unsubscribed' ? 'not active' : subscriptionDetails?.subscriptionStatus;
  if (subscriptionDetails?.subscriptionId) {
    subscriptionId = subscriptionDetails.subscriptionId;
  }

  useEffect(() => {
    window.utag?.view({
      ...defaultAnalyticsVariables,
      event_name: [events.pageView],
      page_name: analyticsName,
      visitor_addon_type_active: `${defaultAnalyticsVariables.app_name}: ${subscriptionStatus}`,
    });
  }, []);

  const onClicAnalytics = () => {
    window.utag?.link({
      ...defaultAnalyticsVariables,
      event_name: [events.interaction],
      page_name: analyticsName,
      link_id: `${pagePrefix}: cancel subscription`,
      visitor_addon_type_active: `${defaultAnalyticsVariables.app_name}: ${subscriptionStatus}`,
    });
  };

  const onCancelSubscription = () => {
    setLoading('Loading...');
    inactivateSubscription(bearerToken, subscriptionId)
      .then(response => {
        if (response.status === 200) {
          navigateTo(ROUTES.CANCELLATION);
        }
      })
      .catch(error => {
        if (error instanceof NetworkError) captureException(new Error(`Failed to Unsubscribe: ${error.response.status}`));
        else captureException(error);
        setSnackBar({
          icon: <FailIcon />,
          body: 'Unsubscription failed. Please try again later.',
        });
      })
      .finally(() => {
        setLoading(null);
      });
  };

  return (
    <div className='content'>
      <TopBack />
      <div className='bag-icon'>
        <BagIcon />
      </div>
      <div className='text-container'>
        <p className='text'>Are you sure you want to cancel?</p>
        <p className='subText-container'>If you cancel now, you&apos;ll miss out on up to <strong>50%</strong> in VodaPay Club rewards.</p>
      </div>
      <div className='dual-buttons-content'>
        <DualButtons
          primaryOnClick={
            () => {
              onCancelSubscription();
              onClicAnalytics();
            }
          }
          secondaryOnClick={ () => navigateTo(ROUTES.VODAPAY_CLUB) }
          primaryText={ 'Yes, cancel' }
          secondaryText={ 'No, keep my subscription' }
        />
      </div>
      { loading && <Loading headerText={ loading } /> }
    </div>
  );
}

export default withProfiler(AreYouSurePage);
