import React from 'react';
import './FallenRocketErrorPage.css';
import FallenRocket from '../../assets/FallenRocket.jsx';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../services/Constants/GlobalRoutes.jsx';

/**
 * @returns {JSX.Element} FallenRocketErrorPage
 */
export default function FallenRocketErrorPage() {
  const navigateTo = useNavigate();
  return (
    <div className='fallen-rocket-container'>
      <div className='fallen-rocket-icon'>
        <FallenRocket />
      </div>
      <div className='text-container'>
        <p className='text'>Oh no! <br />Something went wrong</p>
        <p className='sub-text'>An error occurred while processing your payment. Please try again.</p>
      </div>
      <div className='button-container'>
        <button
          className='main-button'
          onClick={ () => navigateTo(ROUTES.HOME) }
        >
          Back to home
        </button>
      </div>
    </div>
  );
}
