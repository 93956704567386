const ROUTES = {
  ADVANCE_SUMMARY: '/Advance/Summary',
  HOME: '/',
  CATEGORY: { get: id => (id ? `/Category/${id}` : '/Category/:id') },
  CART: '/Cart',
  CROSS_SELL: '/Cross Sell',
  ERROR: '/Error',
  FAILURE: '/Failure',
  VENDOR: { get: id => (id ? `/Vendor/${id}` : '/Vendor/:id') },
  MY_VOUCHERS: '/My Vouchers',
  SHARE: '/Share',
  SUCCESS: '/Success',
  MANAGE_ADVANCE: '/Manage',
  PAYMENT_SELECT: '/Pay',
  SUMMARY: '/Summary',
  NOT_FOUND: '/404',
  MAINTENANCE: '/Maintenance',
  VOUCHER_INFO: '/My Vouchers/Info',
  VODAPAY_CLUB: '/VodaPayClub',
  JOIN_VODAPAY_CLUB: '/JoinVodaPayClub',
  VODAPAY_CLUB_STORE: '/VodaPay Club Store',
  ARE_YOU_SURE: '/Are You Sure',
  CANCELLATION: '/Cancellation',
  ROCKET_ERROR: '/Rocket Error',
  PURCHASE_SUCCESSFUL: '/Purchase Successful',
  SUB_SUMMARY: '/Sub Summary',
};

export default ROUTES;
