import React from 'react';
import { useNavigate } from 'react-router-dom';
import './PurchaseSuccessful.css';
import SuccessTickIcon from '../../assets/SuccessTickIcon.jsx';
import ROUTES from '../../services/Constants/GlobalRoutes.jsx';

/**
 * @returns {JSX.Element} PurchaseSuccessful Component
 */
export default function PurchaseSuccessful() {
  const navigateTo = useNavigate();
  return (
    <div className='purchase-successful-container'>
      <div className='purchase-successful-icon'>
        <SuccessTickIcon />
      </div>
      <div className='text-container'>
        <p className='text'>Purchase Successful!</p>
        <p className='sub-text'>You&apos;re now a member of the exclusive VodaPay VodapPay Club.
          We&apos;ll deduct your next payment from [Payment method] in 7 days.</p>
      </div>
      <div className='button-container'>
        <button
          className='main-button'
          onClick={ () => navigateTo(ROUTES.HOME) }
        > Done </button>
      </div>
    </div>
  );
}
