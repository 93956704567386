import React, { useContext, useEffect, useRef, useState } from 'react';
import { BearerTokenContext, UpdateBearerTokenContext } from '../services/context/BearerTokenContext.js';
import useApis from '../services/hooks/useApis.js';
import PropTypes from 'prop-types';
import { UpdateUserDetailsContext } from '../services/context/UserDetailsContext.js';
import { ConfigContext } from '../services/context/ConfigContext.js';
import useAuthService from '../services/hooks/useAuthService.js';
import { withSentrySpan } from '../services/Sentry.js';
import { startTransaction, captureException, setTag } from '@sentry/react';
import { SentryConfig } from '../services/Constants/Sentry.js';
import { defaultAnalyticsVariables, events, pagePrefix, traceId } from '../services/Constants/Analytics.js';
import { AnalyticsPageContext } from '../services/context/AnalyticsPageContext.js';
import { UpdateMaintenanceMessageContext } from '../services/context/MaintenanceContext.js';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../services/Constants/GlobalRoutes.jsx';
import { NetworkError } from '../services/Constants/Errors.js';

/**
 * @param {object} props - The props for the controller
 * @param {React.ReactNode} props.slot - The content to display within this controller
 * @returns {JSX.Element} The Controller Component
 */
export default function BearerTokenController({ slot }) {
  const authErrorMessage = 'Something went wrong and we were unable to retrieve your profile information, please try again later. We apologise for any inconvenience caused.';
  const [bearerToken, setBearerToken] = useState(/** @type {string} */null);
  const expiryRef = useRef(/** @type {number} */null);
  const { doAuth } = useAuthService();
  const { getBearerToken } = useApis();
  const analyticsName = useContext(AnalyticsPageContext);
  const setUserDetails = useContext(UpdateUserDetailsContext);
  const config = useContext(ConfigContext);
  const navigateTo = useNavigate();
  const setErrorMessage = useContext(UpdateMaintenanceMessageContext);

  const getBearerFromAuthCode = authCode => {
    const bearerTokenTransaction = startTransaction(SentryConfig.auth.bearerToken.transaction);

    withSentrySpan(
      bearerTokenTransaction,
      SentryConfig.auth.bearerToken.spans.getBearerToken,
      () => getBearerToken(authCode),
    )
      .then(response => {
        setBearerToken(response.data.tokens.accessToken);
        localStorage.setItem('subscriberType', response.data.kyc.subscriberType);
        const expiry = new Date(response.data.tokens.expiry);
        expiryRef.current = setTimeout(() => setBearerToken(null), expiry - Date.now() - (30 * 1000));
        const { msisdn, vsp, subscriberId, userId, email, firstName, lastName, fullName } = response.data.kyc;
        setUserDetails({
          vsp,
          subscriberId,
          userId,
          email,
          msisdn: msisdn.replace('-', ''),
          msisdnDash: msisdn,
          firstName,
          lastName,
          fullName,
        });
        setTag('msisdn', msisdn.replace('-', ''));
        setTag('userId', userId);
      }).catch(error => {
        if (error instanceof NetworkError) {
          const { response } = error;
          window.utag?.link({
            ...defaultAnalyticsVariables,
            page_name: analyticsName,
            event_name: [events.error],
            link_id: `${pagePrefix}: error`,
            event_error_name: 'fs auth Error',
            event_error_code: response?.headers?.get(traceId),
            event_error_type: 'system error',
          });
          captureException(new Error('FS Auth Error'));
          setErrorMessage(authErrorMessage);
          navigateTo(ROUTES.ERROR, { replace: true });
        } else throw error;
      }).finally(() => bearerTokenTransaction.finish());
  };

  useEffect(() => {
    if (!bearerToken && config) {
      clearTimeout(expiryRef.current);
      const initAuthTransaction = startTransaction(SentryConfig.auth.initialAuth.transaction);

      withSentrySpan(
        initAuthTransaction,
        SentryConfig.auth.initialAuth.spans.initAuth,
        () => doAuth(),
      ).then(authCode => withSentrySpan(
        initAuthTransaction,
        SentryConfig.auth.initialAuth.spans.getBearerToken,
        () => getBearerFromAuthCode(authCode),
      )).catch(() => {
        window.utag?.link({
          ...defaultAnalyticsVariables,
          page_name: analyticsName,
          event_name: [events.error],
          link_id: `${pagePrefix}: error`,
          event_error_name: 'miniapp authcode error',
          event_error_type: 'system error',
        });
        captureException(new Error('MiniApp getAuth Error'));

        setErrorMessage(authErrorMessage);
        navigateTo(ROUTES.ERROR, { replace: true });
      }).finally(() => initAuthTransaction.finish());
    }
  }, [
    bearerToken,
    config,
  ]);

  return (
    <BearerTokenContext.Provider value={ bearerToken }>
      <UpdateBearerTokenContext.Provider value={ setBearerToken }>
        {slot}
      </UpdateBearerTokenContext.Provider>
    </BearerTokenContext.Provider>
  );
}

BearerTokenController.propTypes = { slot: PropTypes.element };
