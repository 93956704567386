import React, { useState } from 'react';
import './LandingPagePopUp.css';
import ROUTES from '../../services/Constants/GlobalRoutes.jsx';
import { useNavigate } from 'react-router-dom';
import PopUpImage from '../../assets/PopUpImage.png';
import DualButtons from '../../components/DualButtons/DualButtons.jsx';
import PropTypes from 'prop-types';

/**
 *
 * @param {object} props - the props of the component
 * @param {PropTypes.func} props.onShowPopupChange - function to show the popup
 * @returns {JSX.Element} AdvanceInfo Component
 */
export default function LandingPagePopUp({ onShowPopupChange }) {
  const [showPopup, setShowPopup] = useState(true);
  const navigateTo = useNavigate();

  const handleClose = () => {
    setShowPopup(false);
    onShowPopupChange(false);
  };

  if (!showPopup) return null;

  return (
    <div className='popup-container'>
      <div className='popup-content'>
        <img
          src={ PopUpImage }
          alt='Discount Banner'
          className='popup-image'
        />
        <div className='header-text'>
          UP TO 50% EXTRA VALUE
        </div>
        <div className='description'>
          <div className='popup-description'>
            Exclusive deals & vouchers from brands!
          </div>
          <div className='sub-description'>Unlock more value, every time you shop</div>
        </div>
        <div className='popup-button-block'>
          <DualButtons
            primaryOnClick={ () => navigateTo(ROUTES.JOIN_VODAPAY_CLUB) }
            secondaryOnClick={ handleClose }
            primaryText={ 'Try one week for R0.00' }
            secondaryText={ 'Close' }
          />
        </div>
      </div>
    </div>
  );
}

LandingPagePopUp.propTypes = { onShowPopupChange: PropTypes.func };
