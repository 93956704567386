import React, { useContext, useEffect, useState } from 'react';
import './BottomNav.css';
import NavHome from '../NavItem/Home/NavHome.jsx';
import NavMyVouchers from '../NavItem/My Vouchers/NavMyVouchers.jsx';
import NavVodaPayClub from '../NavItem/VodaPay Club/NavVodaPayClub.jsx';
import NavCart from '../NavItem/Cart/NavCart.jsx';
import { useLocation, useNavigate } from 'react-router-dom';
import ROUTES from '../../services/Constants/GlobalRoutes.jsx';
import { SubscriptionDetailsContext } from '../../services/context/SubscriptionDetailsContext.js';
import { defaultAnalyticsVariables, events, pagePrefix } from '../../services/Constants/Analytics.js';
import { UpdateSnackBarContext } from '../../services/context/SnackBarContext.js';
import FailIcon from '../../assets/FailIcon.jsx';

/**
 * @returns {JSX.Element} BottomNav Component
 */
export default function BottomNav() {
  const analyticsName = `${pagePrefix}: landing`;
  const navigateTo = useNavigate();
  const location = useLocation();
  const subscriptionDetails = useContext(SubscriptionDetailsContext);
  const [disableVodapayIcon, setDisableVodapayClubIcon] = useState(false);
  const setSnackBar = useContext(UpdateSnackBarContext);

  useEffect(() => {
    if ((subscriptionDetails && subscriptionDetails?.expiryDate) || (subscriptionDetails === null)) {
      setDisableVodapayClubIcon(false);
    } else {
      setDisableVodapayClubIcon(true);
    }
  }, [subscriptionDetails]);

  const onClickVodaPayClub = details => {
    if (!details) {
      setSnackBar({
        icon: <FailIcon />,
        body: 'Whoops, Something went wrong',
      });
      return;
    }

    const route = details.isSubscribed ? ROUTES.VODAPAY_CLUB : ROUTES.JOIN_VODAPAY_CLUB;
    navigateTo(route);
  };

  const subscriptionStatus = subscriptionDetails?.subscriptionStatus === 'unsubscribed' ? 'not active' : subscriptionDetails?.subscriptionStatus;

  const onClickAnalytcics = () => {
    window.utag?.link({
      ...defaultAnalyticsVariables,
      event_name: [events.interaction],
      page_name: analyticsName,
      link_id: `${pagePrefix}: menu: vodapay: club`,
      visitor_addon_type_active: `${defaultAnalyticsVariables.app_name}: subscription: ${subscriptionStatus}`,
    });
  };

  return (
    <nav className='bottom-nav'>
      <div className='v-container'>
        <button
          className='container-h'
          onClick={ () => navigateTo(ROUTES.HOME) }
        >
          <NavHome />
        </button>
      </div>
      <div className='v-container'>
        <button
          className='container-h'
          onClick={ () => navigateTo(ROUTES.MY_VOUCHERS) }
        >
          <NavMyVouchers />
        </button>
      </div>
      <div className='v-container'>
        <button
          className='container-h'
          disabled={ disableVodapayIcon }
          onClick={
            () => {
              onClickVodaPayClub(subscriptionDetails);
              onClickAnalytcics();
            }
          }
        >
          <NavVodaPayClub />
        </button>
      </div>
      <div className='v-container'>
        <button
          className='container-h'
          onClick={ () => (location.pathname === ROUTES.CART ? navigateTo(ROUTES.CART) : navigateTo(ROUTES.CROSS_SELL)) }
        >
          <NavCart />
        </button>
      </div>
    </nav>
  );
}
