import React, { useContext, useEffect, useState } from 'react';
import './SubscriptionSummary.css';
import MiniAppMessageTypes from '../../services/Constants/MiniAppMessageTypes.js';
import DualButtons from '../../components/DualButtons/DualButtons.jsx';
import TopBack from '../../components/TopBack/TopBack.jsx';
import KeyValueList from '../../components/KeyValueList/KeyValueList.jsx';
import PhoneCall from '../../assets/PhoneCall.jsx';
import BillIcon from '../../assets/BillIcon.jsx';
import { SubscriptionDetailsContext } from '../../services/context/SubscriptionDetailsContext.js';
import moment from 'moment';
import { BearerTokenContext } from '../../services/context/BearerTokenContext.js';
import useApis from '../../services/hooks/useApis.js';
import { NetworkError } from '../../services/Constants/Errors.js';
import { captureException } from '@sentry/react';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../services/Constants/GlobalRoutes.jsx';
import { UserDetailsContext } from '../../services/context/UserDetailsContext.js';
import { UpdateSnackBarContext } from '../../services/context/SnackBarContext.js';
import FailIcon from '../../assets/FailIcon.jsx';
import { UpdateRocketContext } from '../../services/context/RocketContext.js';
import Loading from '../../components/Loading/Loading.jsx';
/**
 * @returns {JSX.Element} CartPage component
 */
export default function SubscriptionSummary() {
  const userDetails = useContext(UserDetailsContext);
  const bearerToken = useContext(BearerTokenContext);
  let subscriptionDetails = useContext(SubscriptionDetailsContext);
  const setSnackBar = useContext(UpdateSnackBarContext);
  const setRocket = useContext(UpdateRocketContext);
  const [condition, setCondition] = useState(false);
  const [loading, setLoading] = useState(/** @type {string} */null);
  const { getSubscriptionDetails, activateSubscription } = useApis();
  const navigateTo = useNavigate();

  const airtimeAdvance = localStorage.getItem('Airtime-advance') || undefined;
  const airtimeAdvanceValue = `R ${Number(airtimeAdvance).toFixed(2)}`;
  const dateAfterOneMonth = moment().add(1, 'months');
  const paymentDateForSubscription = dateAfterOneMonth.format('D MMMM YYYY');
  const dateAfterSevenDays = moment().add(7, 'days');
  const paymentDateForTrialVersion = dateAfterSevenDays.format('D MMMM YYYY');
  const subscriptionFee = `R ${Number(subscriptionDetails?.subscriptionFee).toFixed(2)}`;
  const subscriberType = localStorage.getItem('subscriberType');
  const textOnSuccessPage = subscriberType === 'PREPAID' ? 'your airtime' : 'your bill';
  const isTrialAvailable = subscriptionDetails?.isTrialAvailable;

  // Get subscription Details API call when, time expires / time exceed than 3 min
  const subscriptionDetailsApiCall = () => {
    setLoading('Loading subscription details...');
    subscriptionDetails = null;
    getSubscriptionDetails(bearerToken)
      .then(response => {
        const expiryDate = Date.now();
        switch (response.status) {
          case 200: {
            response.isSubscribed = false;
            response.isTrialAvailable = response?.data?.isTrialAvailable;
            response.packageId = response?.data?.trialPackageId || response?.data?.packages[0]?.id;
            const subFee = response?.data?.packages.filter(item => item?.product?.taxIncludedPrice !== '0.0');
            response.subscriptionFee = subFee[0]?.product?.taxIncludedPrice;
            response.expiryDate = expiryDate;
            response.subscriptionStatus = 'unsubscribed';
            setLoading(null);
            subscriptionDetails = response;
            break;
          }

          case 409: {
            response.isSubscribed = true;
            response.isTrialAvailable = false;
            response.isShowPopUp = false;
            response.expiryDate = expiryDate;
            response.subscriptionId = response?.data?.subscriptionId;
            response.subscriptionStatus = response?.data?.result?.SubscriptionStatus;
            response.expiryDateAfterInactivation = response?.data?.result?.expirationDate;
            setLoading(null);
            subscriptionDetails = response;
            break;
          }

          case 423: {
            response.subscriptionStatus = 'restricted';
            response.isShowPopUp = false;
            response.expiryDate = expiryDate;
            setLoading(null);
            subscriptionDetails = response;
            break;
          }
        // No default
        }
      })
      .catch(error => {
        setLoading(null);
        subscriptionDetails = null;
        if (error instanceof NetworkError) captureException(new Error(`Failed to get summray details: ${error.response.status}`));
        else captureException(error);
      });
  };

  useEffect(() => {
    const checkExpiryTime = () => {
      const currentTime = Date.now();
      const timeDifference = currentTime - subscriptionDetails?.expiryDate;
      if (timeDifference > 3 * 60 * 1000) {
        setCondition(true);
      } else {
        setCondition(false);
      }
    };

    checkExpiryTime();
    if (condition) {
      subscriptionDetailsApiCall();
    }
  }, [
    subscriptionDetails,
    condition,
  ]);

  // On click of subscribe option
  const subscribeUser = () => {
    const packageId = subscriptionDetails?.packageId;
    const info = {
      icon: <FailIcon />,
      body: '',
    };
    setLoading('Loading...');
    activateSubscription(bearerToken, packageId)
      .then(response => {
        switch (response.status) {
          case 200: {
            setRocket({
              analytics: { page_name: 'payment success' },
              body: <p className='sub-text'>`You&apos;re now a member of the exclusive VodaPay VodapPay Club.
                We&apos;ll deduct your next payment from {textOnSuccessPage} in 7 days.`</p>,
              header: <p className='text'>Purchase Successful!</p>,
              onContinue: () => navigateTo(ROUTES.HOME, { replace: true }),
            });
            navigateTo(ROUTES.SUCCESS);
            break;
          }

          case 409: {
            info.body = 'You have already Subscribed.';
            setSnackBar({ info });
            break;
          }

          case 423: {
            info.body = 'Customer profile is restricted.';
            setSnackBar({ info });
            break;
          }

          default: {
            info.body = 'Something went wrong. Please try again later.';
            setSnackBar({ info });
          }
        }
      })
      .catch(error => {
        setLoading(null);
        if (error) {
          navigateTo(ROUTES.ROCKET_ERROR);
        }
      });
  };

  const maskMsisdn = number => {
    const value = number.toString().slice(-4);
    return value.padStart(number.toString().length, '*');
  };

  return (
    <section className='subscription-summary v-container'>
      <TopBack />
      <div className='summary-container'>
        <div className='summary-heading'>Summary</div>
        <div className='details-container'>
          <section className='voucher-details'>
            <KeyValueList object={
              {
                'Product name': 'VodaLend VodaPay Club',
                'Next payment date': isTrialAvailable ? paymentDateForTrialVersion : paymentDateForSubscription,
                'Payment frequency': 'Monthly',
                'Subscription fee': subscriptionFee,
              }
            }
            />
          </section>
        </div>
      </div>
      <h3 className='select-payment-method'>Payment method:</h3>
      {
        subscriberType === 'PREPAID' ? (
          <div className='airtime'>
            <div className='icon-details-container'>
              <div className='airtime-icon'>
                <PhoneCall />
              </div>
              <div className='airtime-details'>
                <div className='airtime-text'>Airtime</div>
                <div className='airtime-balance'>Available {airtimeAdvanceValue}</div>
              </div>
            </div>
          </div>
        ) : (<div className='airtime'>
          <div className='icon-details-container'>
            <div className='airtime-icon'>
              <BillIcon />
            </div>
            <div className='airtime-details'>
              <div className='airtime-text'>Add to bill</div>
              <div className='airtime-balance'>Account {maskMsisdn(userDetails.msisdn)}</div>
            </div>
          </div>
        </div>
        )
      }
      <h3 className='cost-details-heading'>Cost details</h3>
      <div className='cost-details-container'>
        <div className='cost-details-text'>Total</div>
        {
          isTrialAvailable ? (
            <div className='cost-details-balance'>{subscriptionFee}/month after 7 days</div>
          ) : (
            <div className='cost-details-balance'>{subscriptionFee} per month</div>
          )
        }
      </div>
      <p className='footer-condition'>By tapping &quot;Subscribe&quot; you agree to the Vouchers
        <button
          className='footer-condition-link'
          onClick={ () => window.my.postMessage({ messageType: MiniAppMessageTypes.termsAndCond }, '*') }
        ><a>Terms & Conditions</a></button>
      </p>
      <div className='dual-buttons-container'>
        <DualButtons
          primaryOnClick={ () => subscribeUser() }
          secondaryOnClick={ () => navigateTo(-1) }
          primaryText={ 'Subscribe' }
          secondaryText={ 'Cancel' }
        />
      </div>
      { loading && <Loading headerText={ loading } /> }
    </section>
  );
}
